import moment from "moment";
import React, { Fragment } from "react";
import { Link } from 'react-router-dom';
import { useDispatch } from "react-redux";
import { useEffect, useState } from 'react';

import 'react-circular-progressbar/dist/styles.css';
import GoogleDrive from "../../../components/svgs/GoogleDrive";
import { getRequest, postRequest, showMessage } from '../../../config';
import PassFailCertification from "../../../components/PassFailCertification";
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';

export default function Report({ getCompany, sectionRefs, user, company, moduleCertification, module, tab, setTab, aiEnhanced, setAiEnchaned, twoMinuteTestAIReport }) {

	const dispatch = useDispatch();

	let [checklistModule, setChecklistModule] = useState(false);
	let [directorCertification, setDirectorCertification] = useState('None');

	const getHeight = child => child.offsetHeight + parseFloat(getComputedStyle(child).marginTop) + parseFloat(getComputedStyle(child).marginBottom);

	useEffect(() => {
		if (!module) setTab(tab);
		if (tab && module && tab.includes(`directors-questionnaire_${module?.['director']?.['_id']}-report`)) {
			if (module?.stats?.completionPercent >= 90 && module?.stats?.riskPercent <= 50) setDirectorCertification('Gold')
			else if (module?.stats?.completionPercent >= 75 && module?.stats?.riskPercent <= 50) setDirectorCertification('Silver')
			else setDirectorCertification('None')
		}
	}, []);

	useEffect(() => {
		if (user['role'] == 'Guest' && company?.['keyPerformanceIndicators']?.length == 0) getCompany();
	}, []);

	useEffect(() => {
		if (module?.['type']) {
			if (!(['kpi', `two-minute-test-${company['testType']}`, 'repository', 'software-development', 'esg', 'warranty-disclosure', `business-audit-${company['testType']}`, `directors-questionnaire_${module?.['director']?.['_id']}`].includes(module['type']))) setChecklistModule(true);
		}
	}, [module]);

	useEffect(() => {
		document.getElementById("pdfContent").classList.add("a4-pdf", "p3rem");
		let wrapper = document.getElementById("pdf");
		wrapper.innerHTML = "";
		let children = Array.from(document.getElementById("pdfContent").children);
		let i = 0;

		while (children.length && i < 250) {
			i++;
			let page = document.createElement("div");
			page.classList.add("a4-pdf", "oyh", "bg1", "bsbb", "oh", "pr", "p3rem", "shadow", "new-report");
			if (aiEnhanced || tab == 'kpi-report') page.classList.add('report-ai');

			wrapper.appendChild(page);
			let watermark = document.createElement("img");
			// watermark.src = "/images/favicon.png";
			watermark.classList.add("op20", "pa");
			watermark.style.transform = "translate(-50%, -50%) rotate(30deg)";
			watermark.style.top = "50%";
			watermark.style.left = "50%";
			watermark.style.width = "297mm";
			page.appendChild(watermark);
			let pageHeight = getHeight(page);
			let usedPageHeight = 96;
			let childrenToRemove = 0;

			let footer = document.createElement("div");
			footer.classList.add("tac", "fw600", "df", "fdc", "jcfe", "h100");
			footer.innerText = "Page " + i
			let footerHeight = 8;

			if (i > 1) {
				let header = document.createElement("img");
				// header.src = "/images/logo.png";
				header.classList.add("db", "ma", "h3rem");
				header.innerText = "Page " + i
				page.appendChild(header);

				titleHeading(page);

				usedPageHeight += getHeight(header);
				if (i > 2) {
					// if (checklistModule) riskRecommendationHeaders(page);
					if (module?.['type'].includes("warranty-disclosure")) warrantyDisclosureHeaders(page);
					if (["software-development", "esg"].includes(module?.['type']) || checklistModule) riskRecommendationHeaders(page);
					if (module?.['type'].includes(`directors-questionnaire_${module?.['director']?.['_id']}`)) directorsQuestionnaireHeaders(page);
				}
			}

			for (let child of children) {
				let childHeight = getHeight(child);
				if (usedPageHeight + childHeight + footerHeight < pageHeight || childHeight > pageHeight - 150) {
					child.classList.add("pr");
					page.appendChild(child.cloneNode(true));
					usedPageHeight += childHeight;
					childrenToRemove++;
				} else {
					page.classList.add("df", "fdc");
					page.appendChild(footer);
					break;
				}
			}
			if (childrenToRemove == 0) {
				console.log("Remove", children.length, childrenToRemove);
				break;
			}

			children.splice(0, childrenToRemove);
		}

		wrapper.classList.remove("op0", "pen");
		document.getElementById("pdfContent").classList.remove("a4", "p3rem");

		let pdfInputs = wrapper.querySelectorAll("#pdf input");
		for (let j = 0; j < pdfInputs.length; j++) pdfInputs[j].addEventListener('click', (e) => saveAnswerInput(e));

		let pdfTextareas = wrapper.querySelectorAll("#pdf textarea");
		for (let j = 0; j < pdfTextareas.length; j++) pdfTextareas[j].addEventListener('change', (e) => saveAnswerInput(e));
	});

	const titleHeading = (page) => {
		const title = document.createElement("span");
		title.classList.add("db", "ma", "mb1-75rem", "fw700", "fs1-25rem", "h4rem", "tac", "c6", 'ttu');
		if (tab.match(/two-minute-test-.*-report/)) title.innerText = '2 Minute ' + module?.heading + ' Report' + ' - ' + company['cName'];
		else if (checklistModule) title.innerText = module['type'] + ' Report' + ' - ' + company['cName'];
		else title.innerText = module?.['heading'] + ' Report' + ' - ' + company?.['cName'];

		page.appendChild(title);
		return page;
	};

	const repositoryHeaders = (page) => {
		const headings = document.createElement("div");
		headings.classList.add("dg", "gtcr-audit", "fs0-75rem", "bg6", "c1", "tac");
		headings.innerHTML = "<h3>Question</h3><h3>Answer</h3><h3>Files Uploaded</h3><h3>Notes</h3>";
		page.appendChild(headings);
	};

	const riskRecommendationHeaders = (page) => {
		const headings = document.createElement("div");
		headings.classList.add("dg", "gtcr-audit", "fs0-75rem", "bg6", "c1", "tac");
		headings.innerHTML = "<h3>Question</h3><h3>Answer</h3><h3>Risk</h3><h3>Recommendation</h3>";
		page.appendChild(headings);
	};

	const warrantyDisclosureHeaders = (page) => {
		const headings = document.createElement("div");
		headings.classList.add("dg", "gtcr-audit", "fs0-75rem", "bg6", "c1", "tac");
		headings.innerHTML = "<h3>Question</h3><h3>Answer</h3><h3>Disclosure</h3><h3>Files Uploaded</h3>";
		page.appendChild(headings);
	};

	const directorsQuestionnaireHeaders = (page) => {
		const headings = document.createElement("div");
		headings.classList.add("dg", "gtcr-audit", "fs0-75rem", "bg6", "c1", "tac");
		headings.innerHTML = "<h3>Question</h3><h3>Answer</h3><h3>Text</h3><h3>Files Uploaded</h3>";
		page.appendChild(headings);
	};

	const sortQuestions = (a, b) => {
		let valueA = a.qNo.split('.')[a.qNo.split('.').length - 1]
		let valueB = b.qNo.split('.')[a.qNo.split('.').length - 1]

		return valueA - valueB;
	};

	const sortLogs = (a, b) => new Date(b.timestamp) - new Date(a.timestamp);

	const getAuditContent = () => {
		const sectionAnswers = answers.filter(a => !Object.values(module?.sections).filter(s => s.isHidden).find(s => a.qNo.includes(s.sectionNo)));
		const logs = sectionAnswers.filter(a => a.logs.length).reduce((arr, a) => [...arr, ...a.logs], []).sort(sortLogs);
		return logs.map((log, idx) =>
			<div key={idx} className="bg1 p1rem br5px df my0-5rem">
				{log.userId.profilePicture
					&& <img className="ass ml0-5rem br100 w2rem h2rem" src={log.userId.profilePicture} />
					|| <span className="ass w2rem h2rem fw600 mr0-5rem df jcc aic br100 fs0-75rem bg3 c1">{log.userId.firstName[0]}{log.userId.lastName[0]}</span>
				}
				<div className="">
					<span><b>{log.message}</b> at <b>{new Date(log.timestamp).toLocaleString()}</b></span>
					{log.files.map((file, idx) => <Link key={idx} to={file.location} target="_blank" className="db c3-hover fw600">{file.originalname}</Link>)}
					{(log.payload.answers || [log.payload]).map((answer, idx) => {
						const question = answer.questionId ? questions.find(q => q._id == answer.questionId) : "";
						return <Fragment>
							{question && <p className="mt0-5rem"><b>{question.qNo}</b> - {question.qDescription}</p>}
							{answer.file && <p className="mt0-5rem"><b>Filename</b>: {answer.file.originalname}</p>}
							{answer.status && <p className="mt0-5rem"><b>Changed Status</b>: {answer.status}</p>}
							{answer.radioOption && <p className="mt0-5rem"><b>Selected Option</b>: {answer.radioOption}</p>}
							{answer.comment && <p className="mt0-5rem"><b>Entered Comment</b>: {answer.comment}</p>}
						</Fragment>
					})}
				</div>
			</div>
		);
	};

	const getSyncContent = () => {
		const records = module?.sections.flatMap(section => section?.questions?.flatMap(question => (question?.answer?.logs || []))).sort(sortLogs);
		return <>
			<h2 className="df jcc aic mb1-3rem">
				<GoogleDrive className="w2rem mr0-5rem" />
				Google Drive Synchronization Report
			</h2>
			<div className="dg gtcr3 bg6 c1">
				<h3 className="fs1-5rem p1rem">Date Synced</h3>
				<h3 className="fs1-5rem p1rem">Synced By </h3>
				<h3 className="fs1-5rem p1rem">Files Synced</h3>
			</div>
			{records.map((record, idx) => (
				<div key={idx} className="dg tableRow gtcr3">
					<div className="df fdc jcc aic p1rem">{moment(record['timestamp']).format('lll')}</div>
					<div className="df fdc jcc aic p1rem"><span>{`${record['userId']['firstName']} ${record['userId']['lastName']}`}</span><br />{record['userId']['email']}</div>
					<div className="df fdc jcc aic p1rem" style={{ textAlign: 'left' }}>
						{record['files'].map((file, idx) => <a key={idx} href={file['location']} target="'_blank"><b>File</b>: {file['originalname']}</a>)}
					</div>
				</div>
			))}
		</>
	};

	const sortSections = (a, b) => {
		let valueA = a.sectionNo.match(/\d+/g);
		let valueB = b.sectionNo.match(/\d+/g);

		return valueA - valueB;
	};

	const getOverviewContent = () => {
		let array = [];
		if (['warranty-disclosure', `directors-questionnaire_${module?.['director']?.['_id']}`, 'software-development', 'esg'].includes(module['type'])) {
			if (module?.sections[0]?.subFolders && Object.values(module?.sections[0]?.subFolders).length > 0) array = Object.values(module.sections[0].subFolders).filter(s => !s.isHidden);
		}
		else array = module?.sections.filter(s => !s.isHidden);

		return <div className="minh100vh">
			{checklistModule
				? <h2 className="w200 tac c6 lh2 fs1-75rem ttc">{module?.['type'] + ' Report' + ' - ' + company['cName']}</h2>
				: (tab.match(/two-minute-test-.*-report/)) ?
					<h2 className="w200 tac c6 lh2 fs1-75rem ttc">{'2 Minute ' + module?.heading + ' Report' + ' - ' + company['cName']}</h2>
					: <h2 className="w200 tac c6 lh2 fs1-75rem ttc">{module?.heading + ' Report' + ' - ' + company['cName']}</h2>
			}
			<div className="mt0-5rem mr1rem dg gg1rem minh100vh-report">
				<div className="h100 df fdc jcsb aic">
					<div className="logo-report">
						<img className="h2rem" src={require(`../../../../images/Dg-logo.png`)} alt="TODO" />
					</div>
				</div>
				<div className="report-start">
					<div className="dg gtcr2a gg2rem mb1-3rem">
						<div className="h100 df fdc jcsb aic">
							<div>
								<p className="ass fs1rem mb0-5rem">Company Name</p>
								<h2 className="fs1rem fw700 lh1 ttu usn c6">{company['cName']}</h2>
								{company['testType'] &&
									<>
										<p className="ass fs1rem mt0-5rem mb0-5rem">Test Type</p>
										<h2 className="fs1rem fw700 lh1 ttu usn c6">{company['testType']}</h2>
									</>
								}
							</div>
						</div>
						<div>
							<ul className="right-list">
								{[["Subscription", ((company?.['creatorId']?.['createdBy']?.['role'] == 'Advisor' || company?.['creatorId']?.['createdBy']?.['role'] == 'Investor') && company['subscription'] == 'Pro') ? 'Portfolio PRO' : <>{company['subscription']?.toUpperCase()}</>],
								["Company Reg", company.ukRegNo], ["Issue Date", moment().format("MMM Do YYYY")], ["Generated By", user.email]].map((arr, idx) =>
									<li key={idx}>{arr[0]} <span>{arr[1]}</span></li>
								)}

							</ul>
						</div>
					</div>
					<div className="heading-area mb1rem tac df fdc jcc aic">
						<h2 className="w200 c6 lh2 fs1-25rem" style={{ borderBottom: '3px solid #002d63' }}>Your Overall Result</h2>
					</div>
					<div className={`progress-area-report`}>
						<div className='progress-area' style={{ display: 'flex', position: 'relative', justifyContent: 'space-between', alignItems: 'center' }}>
							<div className='progress-box'>
								<div className="progress-bar" style={{ width: 80, height: 80, backgroundColor: '#ebeeff', borderRadius: '50%' }}>
									<CircularProgressbar
										className={`${module?.stats?.completionPercent > 67 ? 'green'
											: module?.stats?.completionPercent > 33 ? 'orange'
												: 'red'}`}
										value={module?.stats?.completionPercent || 0}
										text={(module?.stats?.completionPercent || 0) + "%"}
										styles={buildStyles({
											strokeLinecap: 'round',
											strokeWidth: '5px',
											textSize: '25px',
											pathTransitionDuration: 0.5,
											trailColor: '#d6d6d6',
											backgroundColor: '#fc7785',
											dominantBaseline: 'ideographic',
										})}
									/>
								</div>
								<span style={{ marginBottom: '0', fontSize: '18px' }} className="text-center-area">Completeness</span>
							</div>
							{tab.match(/two-minute-test-.*-report/)
								? <div className="shadow certification-area br10px">
									{company['moduleCertification'] && <PassFailCertification className={'op70'} testType={company['testType']} level={moduleCertification} expiryTimestamp={company['createdAt']} />}
								</div>
								: <div className="df jcc mb1rem">
									{((tab && module && tab.includes(`directors-questionnaire_${module?.['director']?.['_id']}`)) && directorCertification) ? <>
										{directorCertification == 'None' && <img className='w50' src={require(`../../../../images/insufficient-label.png`)} alt="Label" />}
										{directorCertification == 'Silver' && <img className='w30' src={require(`../../../../images/silver-cup.png`)} alt="Trophy" />}
										{directorCertification == 'Gold' && <img className='w30' src={require(`../../../../images/gold-cup.png`)} alt="Trophy" />}
									</>
										: <>
											{moduleCertification == 'Bronze'
												? <img className={`w30 ${module?.['type'] == "repository" && 'mr7rem'}`} src={require(`../../../../images/bronze-cup.png`)} alt="Trophy" />
												: moduleCertification == 'Silver'
													? <img className={`w30 ${module?.['type'] == "repository" && 'mr7rem'}`} src={require(`../../../../images/silver-cup.png`)} alt="Trophy" />
													: moduleCertification == 'Gold'
														? <img className={`w30 ${module?.['type'] == "repository" && 'mr7rem'}`} src={require(`../../../../images/gold-cup.png`)} alt="Trophy" />
														: <img className={`w50 ${module?.['type'] == "repository" && 'mr7rem'}`} src={require(`../../../../images/insufficient-label.png`)} alt="Label" />
											}</>}
								</div>
							}
							{tab == "repository-report"
								? <div className='progress-box-hidden'></div>
								: <div className='progress-box'>
									<div className="progress-bar" style={{ width: 80, height: 80, backgroundColor: '#ebeeff', borderRadius: '50%' }}>
										<CircularProgressbar
											className={`${module?.stats?.riskPercent > 67 ? 'red'
												: module?.stats?.riskPercent > 33 ? 'orange'
													: 'green'}`}
											value={module?.stats?.riskPercent || 0}
											text={module?.stats?.completionPercent ? `${(module?.stats?.riskPercent || 0)}%` : "0%"}
											styles={buildStyles({
												strokeLinecap: 'butt',
												strokeWidth: '5px',
												textSize: '25px',
												pathTransitionDuration: 0.5,
												trailColor: '#d6d6d6',
												backgroundColor: '#fc7785',
												dominantBaseline: 'ideographic',
											})}
										/>
									</div>
									<span style={{ marginBottom: '0', fontSize: '18px' }} className="text-center-area">Risk</span>
								</div>
							}
						</div>
					</div>
					{array.length > 0 && array.sort(sortSections).map((section, idx) => {
						let completeness = section.stats.completionPercent;
						if (module['type'] == 'repository' && company['subscription'] == 'Lite') {
							if (section.questions) completeness = section.questions.filter(q => q.qNo == section.sectionNo)[0]?.['answer']?.['status'] == 'Completed' ? 100 : 0;
						}
						else completeness = section.stats.completionPercent;

						let riskColour;
						if (module?.type === "repository" || section.stats.riskPercent < 38) riskColour = 7;
						else if (section.stats.riskPercent > 62) riskColour = 9;
						else riskColour = 8;

						let hexColour = riskColour === 8 ? "#ddb64044" : riskColour === 9 ? "#dd404044" : "#40dda144";
						let colorChange = false;
						if (module?.['type'] == "warranty-disclosure") {
							section?.questions.map(question => {
								if (question['answer']['radioOption'] == 'Disclosure' || question['answer']['radioOption'] == 'Not Sure') {
									colorChange = true;
									return;
								}
							})
						}

						return <button key={idx} style={{ background: `linear-gradient(90deg, ${colorChange ? "#f9922a" : hexColour} 0%, ${colorChange ? "#f9922a" : hexColour} ${completeness}%, #f0f0f8 ${completeness}%)` }} data-scroll-to={section.sectionNo} className=" df c3 c6 td250 c3-hover px1rem py0-5rem cp new-report-btn">
							<span className="pen usn ass mr0-5rem tal left-heading">{section.sectionNo}</span>
							<span className="pen uss ass fw600 tal right-detail">{section.sectionName}</span>
						</button>

					})}
				</div>
			</div>
		</div>
	};

	const saveAnswerInput = async (event) => {
		// let answerId = event.target.id.split('_')[1];
		// const res = await postRequest(`/question/addNote/${answerId}`, { done: true });
		// showMessage(res?.['message']);
	};

	const sortQuestionsByQNo = (questions) => {
		if (questions && questions.length > 0) {
			return questions.sort((a, b) => {
				const aParts = a['qNo'].match(/\d+/g).map(Number);
				const bParts = b['qNo'].match(/\d+/g).map(Number);
				for (let i = 0; i < Math.max(aParts.length, bParts.length); i++)
					if ((aParts[i] || 0) !== (bParts[i] || 0)) return (aParts[i] || 0) - (bParts[i] || 0);
				return 0;
			});
		}
	};

	const getAuditReportContent = () => {
		let array = [];
		if (['warranty-disclosure', `directors-questionnaire_${module?.['director']?.['_id']}`, 'software-development', 'esg'].includes(module['type'])) {
			if (module?.sections[0]?.subFolders && Object.values(module?.sections[0]?.subFolders).length > 0) array = Object.values(module.sections[0].subFolders).filter(s => !s.isHidden);
		}
		else array = module?.sections.filter(s => !s.isHidden);

		sectionRefs.current.length = array.length;

		return <>
			{array.length > 0 && array.map((section, index) => {

				let questions;
				const qNo = section.sectionNo || section.no;
				if (module['type'] == 'repository' && company['subscription'] == 'Lite') questions = section.questions && section.questions.filter(q => q.qNo == qNo) || [];
				else questions = section?.questions;

				// questions = questions.sort(sortQuestions);
				questions = sortQuestionsByQNo(questions);

				let completionPercent, riskPercent;
				if (section?.stats?.completionPercent) completionPercent = section.stats.completionPercent;
				if (section?.stats?.riskPercent) riskPercent = section.stats.riskPercent;

				if (module['type'] == 'repository' && company['subscription'] == 'Lite') {
					if (section.questions) completionPercent = section.questions.filter(q => q.qNo == section.sectionNo)[0]?.['answer']?.['status'] == 'Completed' ? 100 : 0;
				}

				return (
					<Fragment>
						<div className="mt1rem" id={section['sectionNo']} ref={(el) => { if (section['sectionNo']) sectionRefs.current[index] = el }}>
							<h2 className="df jcc aic mb1rem fs1rem">
								{section['sectionNo']} - {section['sectionName']} Report &nbsp;-&nbsp;
								{completionPercent && completionPercent > 0
									? <>
										<span className={completionPercent < 34 ? "c9" : completionPercent < 67 ? 'c8' : 'c7'}>Completed {completionPercent || 0}%</span>
										{(riskPercent > 0 && module['type'] != "repository") &&
											<>
												&nbsp;-&nbsp;
												<span className={riskPercent < 38 ? "c7" : riskPercent < 62 ? 'c8' : 'c9'}>{riskPercent || 0}% Risk</span>
											</>
										}</>
									: <>
										<span>Not Started</span>
										{module['type'] != "repository" && <>
											&nbsp;-&nbsp;
											<span>No Risk Score</span>
										</>}
									</>
								}
							</h2>
							<div className={`dg gtcr-audit fs0-75rem bg6 c1 br5px`}>
								<h3 className="tac">Question</h3>
								<h3 className="tac">Answer</h3>
								{module['type'] == "repository" && <>
									<h3 className="tac">Files Uploaded</h3>
									<h3 className="tac">Notes</h3>
								</>}
								{module['type'].includes(`directors-questionnaire_${module?.['director']?.['_id']}`) && <>
									<h3 className="tac">Text</h3>
									<h3 className="tac">Files Uploaded</h3>
								</>}
								{([`business-audit-${company['testType']}`, 'software-development', 'esg'].includes(module['type']) || checklistModule) && <>
									<h3 className="tac">Risk</h3>
									<h3 className="tac">Recommendation</h3>
								</>}
								{module['type'] == "warranty-disclosure" && <>
									<h3 className="tac">Disclosure</h3>
									<h3 className="tac">Files Uploaded</h3>
								</>}
							</div>
						</div>
						{questions && questions.length > 0 && questions.filter(q => q.answer)?.map((question, idx) => {
							if (!aiEnhanced && Object.values(question?.['answer']?.['aiResponse'] || {}).length > 0) setAiEnchaned(true);
							let isRisky = question?.['answer']?.['radioOption'] == "No";

							return (
								<>
									<div key={idx} className={`dg tableRow fs0-75rem ${["repository", `directors-questionnaire_${module?.['director']?.['_id']}`].includes(module?.['type']) ? 'gtcr-audit' : 'gtcr-audit-two'}`}>
										<div className={`df fdc aic jcfs pt0-25rem br5px`}>
											<p><span class="fw600">{question['qNo']}:</span> {question?.['qDescription']}</p>
										</div>
										{module?.['type'].includes(`directors-questionnaire_${module?.['director']?.['_id']}`)
											? <div className="df fdc aic tac jcfs pt1rem br5px">{question?.questionType.includes("YN") ? (question.answer['radioOption'] || question.answer['status']) : "-"}</div>
											: <div className={`df fdc aic tac jcfs pt1rem br5px`}><p class={`${["Disclosure", "No Disclosure"].includes(question?.answer?.['radioOption']) ? ' px0-5rem ' : ' px1rem '} py0-5rem br10px c1 ${['Yes', 'No Disclosure'].includes(question?.answer?.['radioOption']) ? ' bg34' : (question?.answer?.['radioOption'])?.includes('No') ? ' bg4' : (question?.answer?.['radioOption'])?.includes('Disclosure') ? ' bg39' : ' bg16'}`}>
												{question.answer['radioOption'] || question.answer['status'] || '-'}
											</p></div>
										}
										{module?.['type'] == "repository" && <>
											<div className="df fdc aic tac jcfs pt1rem br5px">
												{question?.answer?.files?.length > 0
													? <>{question?.['answer']?.['files'] && question['answer']['files'].map((file, idx) => <a key={idx} href={file['location']} target="'_blank"><b>File</b>: {file['originalname']}</a>)}</>
													: <p>No Files Uploaded</p>
												}
											</div>
											<div className="df fdc aic tac jcfs pt1rem br5px">
												{question?.['answer'] && <p>{question['answer']['comment']}</p>}
											</div>
										</>
										}
										{module?.['type'].includes(`business-audit-${company['testType']}`) && <>
											{question?.['answer']?.['status'] == 'Completed' &&
												<div className="df fdc tal jcfs pt0-5rem">
													<div className="df pb0-5rem">
														<div className="w50 tac px0-5rem">
															{isRisky ? <>
																{question?.['answer']?.['aiResponse']?.['ifriskDescription'] ? question?.['answer']['aiResponse']['ifriskDescription'] : question['riskDescription'] ? question['riskDescription'] : '-'}
															</> : "Well done"
															}
														</div>
														<div className="w50 tac px0-5rem">
															{isRisky ? <>
																{question?.['answer']?.['aiResponse']?.['ifrecommendDescription'] ? question?.['answer']?.['aiResponse']?.['ifrecommendDescription'] : question['recommendDescription'] ? question['recommendDescription'] : '-'}
															</> : "No recommendation at this time"
															}
														</div>
													</div>
													{/* Report-Note */}
													{/* <div style={{ background: '#e1e1f155' }}>
													{question['answer'] &&
														<div className="df fdc jcc aic">
															<div><textarea className=" h100 br5px p0-5rem fs0-75rem" id={'note_' + question['answer']['_id']} name='note' onChange={saveAnswerInput} value={question['answer']['note']} style={{ backgroundColor: "transparent", resize: "vertical" }} placeholder="Add note" rows="1" cols="50"></textarea></div>
															<div className=" df jcsa">
																{(question['answer']['done'] || question['answer']['note']) &&
																	<p>Last Edited: {moment(Date.now(question?.['answer']?.['updatedAt'] && question?.['answer']?.['updatedAt'])).format('ll')}</p>
																}
																<span className="checkobox-report asc pr ml1rem">
																	<input style={{ width: '25px', height: '25px' }} id={'done_' + question['answer']['_id']} name='status' onClick={saveAnswerInput} checked={question['answer']['done'] ? true : false} className="checkbox bg5 shadow bo5 bw2px bss cp td250 br5px" type="checkbox" defaultChecked={false} />
																</span>
															</div>
														</div>
													}
												</div> */}
												</div>
											}
										</>}
										{checklistModule && question?.['answer']?.['status'] == 'Completed' && <>
											<div className='df fdc tal jcs pt0-5rem '>
												<div className="df pb0-5rem">
													<div className="w50 tac px0-5rem">{question?.['riskDescription'] || question?.['ifNo']}</div>
													<div className="w50 tac px0-5rem">
														{question?.['recommendDescription'] || question?.['toptip'] || (question.answer.radioOption == "Yes" && question?.['ifYes'])}
													</div>
												</div>
												<p class="w90 p1rem my0-5rem" style={{ background: '#e1e1f155' }}>Files:
													{question?.['answer']?.['files'] && question?.['answer']?.['files'].length > 0 ? question['answer']['files'].map((file, idx) => <a className="ml0-5rem tdu" key={idx} href={file['location']} target="'_blank">
														{file['originalname']}</a>)
														: <span className="ml0-5rem fs0-75rem fw500">No files uploaded</span>}
												</p>
												{question?.['answer']?.['comment'] &&
													<p class="w90 p1rem my0-5rem" style={{ background: '#e1e1f155' }}>Note: {question?.['answer']?.['comment']}</p>
												}
											</div>
										</>
										}
										{module?.['type'].includes(`directors-questionnaire_${module?.['director']?.['_id']}`) && <>
											<div className="df fdc aic tac jcfs pt1rem">
												{question?.questionType.includes("Date")
													? <>{question?.answer && <p>{moment(question?.answer?.date).format('ll')}</p>}</>
													: <>{question?.['answer'] && <p>{question['answer'] ? (question['answer']['comment'] || question['answer']['dropdown']) : '-'}</p>}</>
												}
											</div>
											<div className="df fdc aic tac jcfs pt1rem">
												{question.answer.files.length > 0
													? <>{question?.['answer']?.['files'] && question['answer']['files'].map((file, idx) => <a key={idx} href={file['location']} target="'_blank"><b>File</b>: {file['originalname']}</a>)}</>
													: <p>-</p>
												}
											</div>
										</>
										}
										{["warranty-disclosure", "software-development", "esg"].includes(module?.['type']) &&
											<div className='df fdc tal jcfs pt0-5rem'>
												<div className="df pb0-5rem">
													<div className="w50 tac px0-5rem">
														{module?.['type'] == 'warranty-disclosure' && question['answer']['radioOption'] == 'Disclosure'
															? question?.['answer']?.['comment'] && question?.['answer']?.['comment']
															: module?.['type'] == 'warranty-disclosure' ? '-' : question?.['answer']?.['aiResponse']?.['ifriskDescription'] ? question?.['answer']['aiResponse']['ifriskDescription'] : question['riskDescription'] ? question['riskDescription'] : '-'
														}
													</div>
													<div className="w50 tac px0-5rem">
														{module?.['type'] == 'warranty-disclosure'
															? question?.['answer']?.['files'] && question['answer']['files'].map((file, idx) => <a key={idx} href={file['location']} target="'_blank"><b>File</b>: {file['originalname']}</a>)
															: question?.['answer']?.['aiResponse']?.['ifrecommendDescription'] ? question?.['answer']['aiResponse']['ifrecommendDescription'] : question['recommendDescription'] ? question['recommendDescription'] : '-'
														}
													</div>
												</div>
												{(["software-development", "esg"].includes(module?.['type'])) && question?.['answer']?.['files'] && question?.['answer']?.['files'].length > 0 &&
													<p class="w90 p1rem my0-5rem" style={{ background: '#e1e1f155' }}>Files: {question['answer']['files'].map(file => <a class="ml0-5rem tdu" href={file['location']} target="'_blank">{file['originalname']}</a>)}</p>}
												{/* Report-Note */}
												{/* <div className="br5px" style={{ background: '#e1e1f155' }}>
													{question['answer'] &&
														<div className="df fdc jcc aic">
															<div><textarea id={'note_' + question['answer']['_id']} name="note" className=" h100 br5px p0-5rem fs0-75rem" type="checkbox" onChange={saveAnswerInput} value={question['answer']['note']} style={{ backgroundColor: "transparent", resize: "vertical" }} placeholder="Add note" rows="1" cols="50"></textarea></div>
															<div className=" df jcsa">
																<p>Last Edited:- {moment(Date.now(question?.['answer']?.['updatedAt'] && question?.['answer']?.['updatedAt'])).format('ll')}</p>
																<span className="checkobox-report asc pr ml1rem">
																	<input id={'done_' + question['answer']['_id']} name="status" style={{ width: '25px', height: '25px' }} onClick={saveAnswerInput} checked={question['answer']['done'] ? true : false} className="checkbox bg5 shadow bo5 bw2px bss cp td250 br5px" type="checkbox" defaultChecked={false} />
																</span>
															</div>
														</div>
													}
												</div> */}
											</div>
										}
									</div >
								</>
							)
						})}
					</Fragment >
				)
			}
			)}
		</>
	};

	const getTwoMinuteTestContent = () => {

		const writeSections = sections => Object.values(sections).filter(s => !s.isHidden).map((section, index) => {
			const questionsAndAnswers = section?.questions?.slice().sort(sortQuestions).filter(q => q?.answer?.radioOption);
			if (questionsAndAnswers && questionsAndAnswers.length) sectionRefs.current.length = questionsAndAnswers.length;

			return <>
				{questionsAndAnswers && questionsAndAnswers.length > 0 && questionsAndAnswers.map((question, idx) => {
					const { answer } = question;
					if (!aiEnhanced && Object.values(answer?.['aiResponse'] || {}).length > 0) setAiEnchaned(true);
					return <Fragment key={idx}>
						<h3
							id={section.sectionNo}
							ref={(el) => {
								if (section['sectionNo']) {
									sectionRefs.current[index] = el
								}
							}}
							className="usn pen fs1rem fw600 mt-3 tac"
						>
							{section.sectionNo} - {section.sectionName}
						</h3>
						<div className="question-box  c1">
							<div className=""><h4>Question: {question['qNo']}</h4></div>
							<p>{question['qDescription']}</p>
							<p className="mt1rem"><b>Answer:</b> <span className={`${(question['answer']['radioOption'] == 'Yes' || question['answer']['radioOption'] < 3) ? 'green' : 'red'} c4`}>
								{question['answer']['radioOption']}
							</span></p>
						</div>
						<div className="recomended-box">
							{(() => {
								const recommendationKey = ["ifYes", "ifNo", "", "if1", "if2", "if3", "if4", "if5"].find(key => key.includes("if" + answer.radioOption));
								return recommendationKey && question[recommendationKey] && <>
									<p><b>Recommendation:</b></p>
									<p className="fs1rem">{answer?.['aiResponse']?.[recommendationKey] || question[recommendationKey]}</p>
								</>
							})()}
						</div>
					</Fragment>
				})}
			</>
		});
		return writeSections(module?.sections);
	};

	const getKPIContent = () => {
		return (
			<>
				<tr>
					<th className="miw8rem">KPI Name</th>
					<th className="miw8rem">KPI Description</th>
					<th className="miw8rem">KPI Calculation</th>
					<th className="miw8rem">KPI Metric</th>
					{/* <th className="miw8rem">Benchmarking</th> */}
				</tr>
				{company?.keyPerformanceIndicators && company.keyPerformanceIndicators.map((kpi, idx) => (
					<tr key={idx}>
						<td className="miw8rem"><b>{kpi['title'] || '-'}</b></td>
						<td className="miw8rem">{kpi['name'] || '-'}</td>
						<td className="miw8rem">{kpi['calculation'] || '-'}</td>
						<td className="miw8rem">{kpi['metric'] || '-'}</td>
						{/* <td className="miw8rem">Coming Soon</td> */}
					</tr>
				))}
			</>
		)
	};

	return <>
		{company && <>
			<table id="pdf" className="op0 pen ma td250 dg gg2rem"></table>

			{/* Call To Action Buttons */}
			<div className="hideOnDownload mt2rem aic df">
				{(!user.firstName && tab == 'kpi-report') &&
					<div className="w100 df jcc aic fdc ">
						<p className="fs1-25rem mb1rem tac">Now let's view your 2-minute test report...</p>
						{user['role'] == 'Guest' && <>
							<button onClick={() => twoMinuteTestAIReport(module)}
								className="shadow bsbb jcc w200px w9rem df fdc aic fw500  px1rem c1 bg4 br19px cp registerButtonAnimation" style={{ width: '25%', paddingTop: '7px', paddingBottom: '7px' }}>
								View 2-Minute Test Report
							</button>
						</>}
					</div>
				}
				{tab.match(/two-minute-test-.*-report/) && (user?.['role'] == 'Guest') &&
					<div className="w100 df jcc aic">
						<a href={`/company/${company['_id']}`} style={{ width: '15%', paddingTop: '7px', paddingBottom: '7px' }}
							className={`shadow bsbb jcc df fdc aic fw500 my1rem px1rem c1 bg4 br19px cp registerButtonAnimation`}>
							Next
						</a>
					</div>
				}
				{/* Repository */}
				{module && module?.['type'] && module?.['type'] == "repository" &&
					<div className="df jcc aic w100">
						<button onClick={() => setTab("")} className="ml1rem shadow bg3 bg4-hover c1 td250 br5px px1rem py0-5rem fw600 cp ttu bsbb tac df jcc aic">
							Dashboard
						</button>
					</div>
				}
				{/* Business-Audit */}
				{module && module?.['type'] && module?.['type'].includes(`business-audit-${company?.['testType']}`) &&
					<div className="df jcc aic w100">
						<button onClick={() => setTab(module['type'])} className="shadow bg3 bg4-hover c1 td250 br5px px1rem py0-5rem fw600 cp ttu bsbb tac df jcc aic">
							Business Audit
						</button>
						<button onClick={() => setTab("")} className="ml1rem shadow bg3 bg4-hover c1 td250 br5px px1rem py0-5rem fw600 cp ttu bsbb tac df jcc aic">
							Dashboard
						</button>
					</div>
				}
				{/* Warranty-Disclosure */}
				{module && module?.['type'] && module?.['type'] == "warranty-disclosure" &&
					<div className="df jcc aic w100">
						<button onClick={() => setTab("")} className="shadow bg3 bg4-hover c1 td250 br5px px1rem py0-5rem fw600 cp ttu bsbb tac df jcc aic">
							Dashboard
						</button>
					</div>
				}
			</div>

			<table id="pdfContent" className="bsbb w0 h0 oh op0 pen db">
				{tab === "kpi-report"
					? <>
						<h2 className="w200 tac c6 lh2 fs1-75rem mb2rem">{module?.heading + ' Report' + ' - ' + company['cName']}</h2>
						{(!user.firstName && tab == 'kpi-report') &&
							<p className="tac fs1-25rem fw600 mb1rem">Well done for completing your 2-minute {company.testType} readiness test.  As a reward we have created a bespoke KPI report for your company.  Take a look.</p>
						}
						{getKPIContent()}
					</>
					: <>
						{getOverviewContent()}
						{['repository-report', `business-audit-${company?.['testType']}-report`, 'warranty-disclosure-report', 'software-development-report', 'esg-report', `directors-questionnaire_${module?.['director']?.['_id']}`].includes(tab)
							? getAuditReportContent() :
							tab.includes("repository-sync-report") ? getSyncContent() :
								tab.includes("repository-audit-report") ? getAuditContent() :
									tab.match(/two-minute-test-.*-report/) ? getTwoMinuteTestContent() : getAuditReportContent()
						}
					</>
				}
			</table>
		</>}
	</>;
} 